import React, { useState } from 'react';
import './TechnicalSupport.css';

import TechSupportBg1 from "../resources/technical_support_1.svg"
import TechSupportBg2 from "../resources/technical_support_2.svg"
import TechSupportBg3 from "../resources/technical_support_3.svg"

import LeftArrow from '../resources/we_suggest_left_arrow.svg'
import RightArrow from '../resources/we_suggest_right_arrow.svg'

const slides = [
    {
        image: TechSupportBg1,
        title: 'Инициирование',
        content: ['Разработка исходных требований',
            'Разработка общих технических решений',
            'Инспекция, приёмка, контроль состава документации',
            'Контроль состава поставки, соответствия техническому заданию и технико-коммерческому предложению',
            'Контроль реализации'
        ]
    },
    {
        image: TechSupportBg2,
        title: 'Проектирование',
        content: ['Технический проект',
            'Общие технические решения, технические задания',
            'Спецификация оборудования',
            'Принципиальные схемы',
            'Системы визуализации и ПО',
            'Техническая и эксплуатационная документация'
        ]
    },
    {
        image: TechSupportBg3,
        title: 'Реализация',
        content: ['Диагностика неисправностей',
            'Подбор и приобретение запасных частей',
            'Ремонт неисправного оборудования',
            'Сервис'
        ]

    }
];

const TechnicalSupport = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? slides.length - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div class="TechnicalSupport_background">
            <div class="TechnicalSupport_main_container">
                <div class="TechnicalSupport_header">Техническое сопровождение:</div>
                <div class="TechnicalSupport_Body">
                    <div class="TechnicalSupport_Slide">
                        <div class="TechnicalSupport_SlideBody">

                            <div className='TechnicalSupport_slide-wrapper'>
                                <div className="TechnicalSupport_slide-content">
                                    <h2>Результаты:</h2>
                                    <ul>
                                        {slides[currentIndex].content.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div class="TechnicalSupport_Progress">
                            {slides.map((_, index) => (
                                <span
                                    key={index}
                                    className={index === currentIndex ? 'dot active' : 'dot'}
                                ></span>
                            ))}
                        </div>
                    </div>
                    <div class="Stages">
                        <div class="Stages_content">
                            <img src={slides[currentIndex].image}></img>
                        </div>
                        <div class="Stages_title">
                            Этап {currentIndex + 1}.
                            <br />
                            {slides[currentIndex].title}
                        </div>
                    </div>
                    <div class="TechnicalSupport_arrow LeftArrow" onClick={goToPrevious}>
                        <img src={LeftArrow} alt="Left Arrow" />
                    </div>
                    <div class="TechnicalSupport_arrow RightArrow" onClick={goToNext}>
                        <img src={RightArrow} alt="Right Arrow" />
                    </div>
                </div>
            </div>
        </div>

    );
};


export default TechnicalSupport;
