import * as React from "react";

import "./ImageAndText.css"

export default function ImageAndText(props) {
    return (
        <div className="ImageAndText-container">
            <img className="ImageAndText-image"
                src = {props.src}
                alt = {props.text}
            />
            <div className="ImageAndText-text">
                <p>{props.text}</p>
            </div>
        </div>
    );
}