import * as React from "react";

import ImageAndText from "../Components/ImageAndText/ImageAndText.js"
import constants from "../ContentConstants.js"

import './Footer.css'

import LocationImage from '../resources/location.svg'
import PhoneImage from '../resources/phone.svg'
import MailImage from '../resources/mail.svg'

function getYear() {
    return new Date().getFullYear();
}

export default function Footer() {
    return (
        <footer className="footer">
            <div className="first-row">
                <div className="grid-item location-item">
                    <ImageAndText src={LocationImage}
                        text={constants.location}
                    />
                </div>
                <div className="grid-item phone-item">
                    <ImageAndText src={PhoneImage}
                        text={constants.companyPhoneNumber}
                    />
                </div>
                <div className="grid-item mail-item">
                    <ImageAndText src={MailImage}
                        text={constants.companyEmail}
                    />
                </div>
            </div>
            <p className="company-name">
                ООО “СистемаТехники”, {getYear()}
            </p>
        </footer>
    );
}