import React from 'react';
import './IntroCard.css';

import ArrowImage from '../resources/intro_card_arrow.svg'
import IntroCardImage from '../resources/intro_card.png'

const ImageCard = () => {
  return (
    <div className="ImageCard-container">
      <div className="ImageCard-left-image">
        <div className="ImageCard-Frame6">
          <div className="ImageCard-Frame40">
            <div>Система Техники - </div>
            <div>команда технических специалистов для организации работ <br />от проекта до объекта</div>
          </div>
          <div className="ImageCard-Frame41">
            <div>Наша команда создана для решения технических задач любой сложности</div>
          </div>
        </div>
        <img src={ArrowImage} alt="Left" />
      </div>
      <div className="ImageCard-full-image">
        <img src={IntroCardImage} alt="Full" />
      </div>
    </div>
  );
};

export default ImageCard;