import React from "react";
import "./WhyWeTrusted.css";

import BgImage from '../resources/why_we_trusted_background.png'

const content = [
    {
        text: "Наши специалисты имеют богатый практический опыт, что позволяет предложить проверенные решения для различных сфер производства."
    },
    {
        text: "При работе используется оборудование отечественных производителей и строго соблюдается существующая нормативно-техническая документация."
    },
    {
        text: "Мы всегда готовы к сотрудничеству и хотим, чтобы наша команда помогала Вам добиваться поставленных целей!"
    }
];


const Point = ({ number, text }) => {
    return (
        <>
            <div className={"WhyWeTrusted_Points-body"} >
                <div className={`WhyWeTrusted_point-number-bg WhyWeTrusted_Points_P${number}`} >
                    <p>0{number}</p>
                </div>
                <div className={`WhyWeTrusted_point-text-bg WhyWeTrusted_Points_P${number}`}>
                    <p>{text}</p>
                </div>
            </div>
        </>
    );
}

const WhyWeTrusted = () => {
    return (
        <div class='WhyWeTrusted_background' style={{ backgroundImage: `url(${BgImage})` }}>
            <div class="WhyWeTrusted_container">
                <div class="WhyWeTrusted_Points">
                    {content.map((item, index) => (
                        <Point key={index} number={index + 1} text={content[index].text} />
                    ))}
                </div>
                <div class="WhyWeTrusted_Header">Почему нам доверяют?</div>
                <div class="WhyWeTrusted_Footer">
                    <button className="WhyWeTrusted_reach_as_button">Связаться с нами</button>
                </div>
            </div>
        </div>

    );
};

export default WhyWeTrusted;
