import React from "react";

import "./AreasOfActivityCard.css";

import ClosetImage from '../resources/areas_of_activity_closet.svg'

const ItemComponent = ({ text }) => {
    return (
        <div
            style={{
                paddingTop: 10,
                paddingBottom: 10,
                background: '#209FBB',
                borderRadius: 10,
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10,
                display: 'flex',
                width: '100%',
                height: '100%',
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    color: 'white',
                    fontSize: 24,
                    fontFamily: 'Tahoma',
                    fontWeight: '700',
                    lineHeight: '40px',
                    wordWrap: 'break-word',
                }}
            >
                {text}
            </div>
        </div>
    );
};

const AreasOfActivityCard = () => {
    return (
        <div style={{ width: '100%', height: 960, position: 'relative' }}>
            <div className="Background" style={{ width: '100%', height: 960, left: 0, top: 0, position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                <div className="Background" style={{ width: '100%', height: 960, background: 'linear-gradient(0deg, #197C91 13%, #25B9DA 85%)' }}></div>
            </div>
            <div style={{ width: '100%', height: 62, top: 100, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 28, fontFamily: 'Tahoma', fontWeight: '700', wordWrap: 'break-word' }}>Основные направления деятельности:</div>
            <img style={{ width: '70%', height: 700, margin: '0 15%', top: 188, position: 'absolute' }} src={ClosetImage} alt="Фон"/> 
            {/* TODO: fix multiresolution issues */}
            <div
                style={{
                    width: '70%',
                    height: 100,
                    margin: '0 15%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    top: 249, position: 'absolute'
                }}
            >
                <div style={{ flex: '1 1 0', margin: '0 35px' }}>
                    <ItemComponent text="Системы управления" />
                </div>
                <div style={{ flex: '1 1 0', margin: '0 35px' }}>
                    <ItemComponent text="Электрическое оборудование" />
                </div>
                <div style={{ flex: '1 1 0', margin: '0 35px' }}>
                    <ItemComponent text="Системы электропривода" />
                </div>
                <div style={{ flex: '1 1 0', margin: '0 35px' }}>
                    <ItemComponent text="Механическое оборудование" />
                </div>
            </div>
        </div>
    );
};

export default AreasOfActivityCard;
