import React from "react";
import VerticalCard from "./VerticalCard";
import "./OurPrinciplesCard.css";

import ExpIcon from '../resources/principles_exp_icon.svg'
import TimeIcon from '../resources/principles_time_icon.svg'
import UnificationIcon from '../resources/principles_unification_icon.svg'
import SecurityIcon from '../resources/principles_security_icon.svg'

const OurPrinciplesCard = () => {
  return (
    <div className="OurPrinciplesCard-principles-container">
      <h2 className="OurPrinciplesCard-principles-title">Наши принципы:</h2>
      <div className="OurPrinciplesCard-cards-row">
        <VerticalCard
          imageSrc={ExpIcon}
          title="Опыт"
          description="Наши специалисты имеют большой опыт в сфере промышленного производства "
        />
        <VerticalCard
          imageSrc={TimeIcon}
          title="Время"
          description="Экономим ваше время за счёт наших услуг"
        />
        <VerticalCard
          imageSrc={UnificationIcon}
          title="Унификация"
          description="Обеспечиваем применение унифицированных решений, сокращаем совокупную стоимость владения"
        />
        <VerticalCard
          imageSrc={SecurityIcon}
          title="Безопасность"
          description="Обеспечиваем высший уровень безопасности эксплуатации"
        />
      </div>
    </div>
  );
};

export default OurPrinciplesCard;
