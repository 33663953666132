import Header from './Header/Header';

import IntroCard from './IntroCard/IntroCard';
import OurPrinciplesCard from './OurPrinciplesCard/OurPrinciplesCard';
import AreasOfActivityCard from './AreasOfActivityCard/AreasOfActivityCard'
import WeSuggestCard from './WeSuggestCard/WeSuggestCard'

import TechnicalSupport from './TechnicalSupport/TechnicalSupport'
import DevelopTechDocCard from './DevelopTechDocCard/DevelopTechDocCard'
import WhyWeTrusted from './WhyWeTrusted/WhyWeTrusted';
import Footer from './Footer/Footer'

import './Stylesheet.css'

function App() {
  return (
    <>
      <div>
        <Header />
        <IntroCard/>
        <AreasOfActivityCard/>
        <WeSuggestCard/>
        <OurPrinciplesCard/>
        <TechnicalSupport/>
        <DevelopTechDocCard/>
        <WhyWeTrusted/>
        <Footer/>
      </div>
    </>

  );
}

export default App;
