import React, { useState } from 'react';
import './WeSuggestCard.css';


import Bg1Image from '../resources/we_suggest_1_background.png'
import Bg2Image from '../resources/we_suggest_2_background.png'
import Bg3Image from '../resources/we_suggest_3_background.png'

import LeftArrow from '../resources/we_suggest_left_arrow.svg'
import RightArrow from '../resources/we_suggest_right_arrow.svg'

const slides = [
    {
        backgroundImage: Bg1Image,
        title: 'Техническое сопровождение:',
        content: ['Разработка исходных требований',
            'Разработка общих технических решений',
            'Инспекция, приёмка, контроль состава документации',
            'Контроль состава поставки, соответствия техническому заданию и технико-коммерческому предложению',
            'Контроль реализации'
        ]
    },
    {
        backgroundImage: Bg2Image,
        title: 'Разработку систем управления и автоматизации электропривода в составе:',
        content: ['Технический проект',
            'Общие технические решения, технические задания',
            'Спецификация оборудования',
            'Принципиальные схемы',
            'Системы визуализации и ПО',
            'Техническая и эксплуатационная документация'
        ]
    },
    {
        backgroundImage: Bg3Image,
        title: 'Ремонт сложного технического оборудования:',
        content: ['Диагностика неисправностей',
            'Подбор и приобретение запасных частей',
            'Ремонт неисправного оборудования',
            'Сервис'
        ]
        
    }
];

const WeSuggestCard = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? slides.length - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className='slider-wrapper'>
            <div className="background" style={{ backgroundImage: `url(${slides[currentIndex].backgroundImage})` }}></div>
            
            <div className="slider-container">
                <p className='slider-wrapper-header'>Мы предлагаем:</p>
                <div className="arrow left" onClick={goToPrevious}>
                    <img src={LeftArrow} alt="Left Arrow" />
                </div>
                <div className="slide-content-wrapper">
                    <div className='slide-wrapper'>
                        <div className="slide-content">
                            <h2>{slides[currentIndex].title}</h2>
                            <ul>
                                {slides[currentIndex].content.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="progress-bar">
                        {slides.map((_, index) => (
                            <span
                                key={index}
                                className={index === currentIndex ? 'dot active' : 'dot'}
                            ></span>
                        ))}
                    </div>
                </div>
                <div className="arrow right" onClick={goToNext}>
                    <img src={RightArrow} alt="Right Arrow" />
                </div>
            </div>
        </div>
    );
};


export default WeSuggestCard;
