import React from "react";
import "./VerticalCard.css";

const VerticalCard = ({ imageSrc, title, description }) => {
  return (
    <div className="VerticalCard-card">
      <img src={imageSrc} alt={title} className="VerticalCard-card-image" />
      <h3 className="VerticalCard-card-title">{title}</h3>
      <p className="VerticalCard-card-description">{description}</p>
    </div>
  );
};

export default VerticalCard;
